import React from 'react'
import { connect } from 'react-redux'
import { nextPage, prevPage } from '../../../state/action'

const checkForValues = (values, fields) => {
  if(fields) {
    return fields.filter(field => values[field]).length === fields.length
  } else {
    return true
  }
}

const checkForErrors = (errors, fields) => {
  if(fields) {
    return fields.filter(field => errors[field]).length === 0
  } else {
    return true
  }

}

// Sets fields with errors to be touched if there is no errors go to next page
const setPageFieldsTouched = (errors, setFieldTouched, nextPage) => {
  const fields = Object.getOwnPropertyNames(errors);
  fields.map( field => setFieldTouched(field) )
  if( fields.length === 0 ) {
    nextPage()
    window.scrollTo(0, 0)
  }
}

const buttonType = (props) => {
  switch (props.type) {
    case 'next':
      return (
        <button
          type="button"
          onClick={
            () => {
              props.validateForm()
              .then( (errors) => setPageFieldsTouched(errors, props.setFieldTouched, props.nextPage)
              )}
            }
          className={props.className}
          disabled={ !(checkForValues(props.values, props.fields) && checkForErrors(props.errors, props.fields)) }
        >
          {props.text || 'Next'}
        </button>
      )
    case 'back':
      return (
        <button
          type="button"
          className={props.className}
          onClick={props.prevPage}
        >
          {props.text || 'Back'}
        </button>
      )
    case 'submit':
      return (
        <button
          className={props.className}
          type="submit"
          disabled={ props.isSubmitting }
          // disabled={!props.}
        >
          {props.text || 'Submit'}
          
        </button>
      )
    default:
      return null;
  }
}

const Buttons = props => (
  buttonType(props)
)

const mapDispatchToProps = dispatch => {
  return {
    nextPage: () => dispatch( nextPage() ),
    prevPage: () => dispatch( prevPage() )
  }
}

export default connect( null, mapDispatchToProps )( Buttons )
