import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setField, setHomeCityState, clearHomeCityState, setPercent, setTitle, setText, setPageNumber } from '../../../state/action'
import Zip from '@torchlight-technology/zip-field'
import City from '@torchlight-technology/home-city-field'
import Street from '@torchlight-technology/home-street-field'
import FormWrapper from '../components/formWrapper'

class PageTwo extends Component {
  
  componentDidMount(){
    this.props.setPageNumber(2)
    this.props.setPercent('30')
    this.props.setTitle(`<p>Great! We have plans available that will be <span class="highlight">perfect for you.</span> Now, tell us where you're located.</p>`)
    this.props.setText("")
    
  }
  
  render(){
    return (
      <>
        <FormWrapper {...this.props}  heading={""} percentage="30" btnType="two">
          <Zip {...this.props} setCityState noErrorMessage />
          <Street {...this.props} noErrorMessage />
          <City {...this.props} noErrorMessage />
        </FormWrapper>
      </>
    )
  }
} 

const mapDispatchToProps = dispatch => {
  return {
    setField: field => dispatch( setField( field ) ),
    setHomeCityState: data => dispatch( setHomeCityState(data)),
    clearHomeCityState: () => dispatch( clearHomeCityState() ),
    setTitle: title => dispatch( setTitle( title ) ),
    setPercent: percent => dispatch( setPercent( percent ) ),
    setText: text => dispatch( setText( text ) ),
    setPageNumber: number => dispatch( setPageNumber(number) ),
  }
}

export default connect( null, mapDispatchToProps )( PageTwo )