import React from 'react'

const Disclaimer = ({children}) => {
  return (
    <label className="disclaimer" id="disclaimer">
      <input type="hidden" id="leadid_tcpa_disclosure" name="tcpa_consent_language"/>
      {children}
    </label>
  )
}

export default Disclaimer
