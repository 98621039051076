import React from 'react'
import agentImg from '../../../images/janet.png'
import { connect } from 'react-redux'
import Percent from './formPercent'

const firstPageHeading = () => (
  <div className="agent-mobile">
    <img src={agentImg} alt="agent" />
    <h1 className="first-page-heading">Get an Instant Policy Quote in Minutes!</h1>
  </div>
)

const pageHeading = () => (
  <div className="agent-mobile align-center">
    <img src={agentImg} alt="agent" />
  </div>
)

const heading = ({children, pageData}) => {
  return (
    <div className={`form-heading`}>
      {/* { pageData.pageNumber === 1 ? firstPageHeading() : pageHeading()  } */}
      <div className="small-container">
        { pageData.pageNumber === 1 ? null : <Percent text={pageData.text} percentage={pageData.percent} />  }
        
        {/* {pageData.pageNumber === 1 ? <h1 className="first-page-heading">Get an Instant Policy Quote in Minutes!</h1> : null} */}
        <div className="flex-row">
          <div className={`flex-small question ${ pageData.pageNumber === 1 ? 'page-one' : ''  }`}>
            <img className="agent-image" src={agentImg} alt="agent" />
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ formState, page, meta, pageData }) => {
  return { 
    pageData
  }
}

export default connect( mapStateToProps, null )( heading )
