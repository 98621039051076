import React from "react"
import { Field } from "formik"


const validate = values => {
  if (!values) {
    return "Required"
  }
}

const Gender = (props) => (
  <div className="ttg-field">
    <Field 
      className='custom-radio-btn'
      type="radio" 
      name="gender" 
      id="female" 
      value="f" 
      checked={'f' === props.values.gender}
      onChange={ e => {
        props.handleChange(e)
        props.setField(e)
      }}
      onBlur={props.onBlur}
      validate={validate}
      />
    <label 
      className={ `custom-radio-label gender-label ${
        props.errors.gender && props.touched.gender ?  "animation-target" : ""
      }`}
      htmlFor="female">
        Female
    </label>
      
    <Field 
      className="custom-radio-btn" 
      type="radio" 
      name="gender" 
      id="male" 
      value="m"
      checked={'m' === props.values.gender}
      onChange={ e => {
        props.handleChange(e)
        props.setField(e)
      }}
      onBlur={props.onBlur}
      validate={validate}
      />
    <label 
      className={ `custom-radio-label gender-label ${
        props.errors.gender && props.touched.gender ?  "animation-target" : ""
      }`}
      htmlFor="male">
        Male
    </label>
  </div>
)

export default Gender
