import React from 'react'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const formPercent = ({percentage, text, font_size, value }) => {
  return (
    <div className="form-percent">
      <CircularProgressbar backgroundPadding={10} background={true} strokeWidth={8} value={percentage} text={`${text || `${percentage}%`}`}
        styles={{
          // Customize the root svg element
          root: {},
          // Customize the path, i.e. the "completed progress"
          path: {
            // Path color
            stroke: `#F6A04F`,
            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: 'butt',
            // Customize transition animation
            transition: 'stroke-dashoffset 0.5s ease 0s',
            // // Rotate the path
            // transform: 'rotate(0.25turn)',
            // transformOrigin: 'center center',
          },
          // Customize the circle behind the path, i.e. the "total progress"
          trail: {
            // Trail color
            stroke: '#d6d6d6',
            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: 'butt',
            // Rotate the trail
            transform: 'rotate(0.25turn)',
            transformOrigin: 'center center',
          },
          // Customize the text
          text: {
            // Text color
            fill: '#333',
            // Text size
            fontSize: font_size || '22px',
          },
          // Customize background - only used when the `background` prop is true
          background: {
            fill: '#FFF',
          },
        }}
      />
    </div>
  )
}

export default formPercent
