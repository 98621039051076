import React, { Component } from "react"
import { Formik, Form } from "formik"
import objectToFormData from "object-to-formdata"
import axios from "axios"
import { navigate } from "gatsby"
import { connect } from "react-redux"
import {
    setAffParams,
    setIpAddress,
    setUserAgent,
    setUniversalLeadId,
} from "../../state/action"
import { validate } from "./validation"
import PageOne from "./pages/pageOne"
import PageTwo from "./pages/pageTwo"
import PageThree from "./pages/pageThree"
import PageFour from "./pages/pageFour"
import PageFive from "./pages/pageFive"
import Percent from "./components/formPercent"
import Heading from "./components/heading"
import moment from "moment"

const pages = (props) => {
    return {
        1: <PageOne {...props} />,
        2: <PageTwo {...props} />,
        3: <PageThree {...props} />,
        4: <PageFour {...props} />,
        5: <PageFive {...props} />,
    }
}

class FormIndex extends Component {
    state = {
        gender: "",
        dateOfBirth: "",
        name_first: "",
        name_last: "",
        currently_insured: "",
        home_street: "",
        home_zip: "",
        home_state: "",
        home_city: "",
        email: "",
        phone_home: "",
    }

    componentDidMount() {
        const {
            name_first,
            name_last,
            email,
            home_street,
            home_zip,
            home_state,
            home_city,
            phone_home,
            dateOfBirth,
            gender,
            currently_insured,
        } = this.props.formState

        this.setState({
            name_first: name_first || "",
            name_last: name_last || "",
            gender: gender || "",
            email: email || "",
            home_state: home_state || "",
            home_street: home_street || "",
            home_zip: home_zip || "",
            home_city: home_city || "",
            phone_home: phone_home || "",
            dateOfBirth: dateOfBirth || "",
            currently_insured: currently_insured || "",
        })
    }

    claimTrustedForm = (trusted_form_url) => {
        const data = `action=claim&payload={"url":"${trusted_form_url}","vertical":"${process.env.GATSBY_VERTICAL}"}`;
        axios
            .post(
                `${process.env.GATSBY_TRUSTED_FORM_URL}`,
                data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                },
            )
            .then((res) => console.log('trusted from response: ', res.data))
            .catch((error) => console.log(error));
    };

    postToOnePingTree = (values) => {
        console.log("teststts", values)
        // assign meta values to form
        const {
            session_id,
            affid,
            user_agent,
            universal_leadid,
            ip_address,
            sub_id1,
            sub_id2,
            sub_id3,
            client_name,
            tcpa_disclosure,
            landing_url,
            privacy_policy_url,
            testing,
        } = this.props.meta

        values.session_id = session_id
        values.affid = affid
        values.user_agent = user_agent
        values.universal_leadid = universal_leadid
        values.ip_address = ip_address
        values.sub_id1 = sub_id1
        values.sub_id2 = sub_id2
        values.sub_id3 = sub_id3
        values.client_name = client_name
        values.tcpa_disclosure = tcpa_disclosure
        values.landing_url = landing_url
        values.privacy_policy_url = privacy_policy_url
        values.testing = testing
        values.home_state = this.props.formState.home_state

        values.datetime_collected = moment().format("YYYY-MM-DD hh:mm:ss")

        // get disclaimer from label under submit button
        let node = document.getElementById("disclaimer"),
            tcpa_consent_language = node.textContent

        values.tcpa_consent_language = tcpa_consent_language
        console.log("tcpa_consent_language---->", tcpa_consent_language)
        // get date of birth in correct format
        const month = values.dateOfBirth.substr(0, 2)
        const day = values.dateOfBirth.substr(3, 2)
        const year = values.dateOfBirth.substr(6, 4)
        values.dob = year + "-" + month + "-" + day
        delete values.dateOfBirth
        delete values.affid

        values.phone_home = values.phone_home.toString().replace(/\D/g, "")
        //Assign ip address to values
        // values.ip_address = ip_address;

        //Assign trusted from url. Value get from trusted from script on template.html
        let trusted_form_url_value =
            document.getElementById("trusted_form_url_0").value
        values.trusted_form_url = trusted_form_url_value
        console.log("trusted", trusted_form_url_value)

        console.log("form data object: ", values)

        // POST to one.pingtreetech
        const getResponse = (process.env.GATSBY_QA && JSON.parse(process.env.GATSBY_QA)) ? '?response=1' : '';
        fetch(`${process.env.GATSBY_API}${getResponse}`, {
            method: "POST",
            headers: {
                Authorization: `${process.env.GATSBY_API_TOKEN}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
        })
            .then((res) => res.json())
            .then((data) => console.log(JSON.stringify(data)))
        window.location.replace(
            `${process.env.GATSBY_WEB_SITE_URL}/thank-you?home_zip=${values.home_zip}`
        )
        // navigate("/thank-you/", {
        //   state: {values}
        // })

        // Claim Trusted form
        // this.claimTrustedForm(values.trusted_form_url)
    }

    handleSubmit = (values, bag) => {
        const currentPage = this.props.page.pageIndex
        const LastPage = Object.keys(pages()).length

        // let trusted_form_url_value =  document.getElementById('trusted_form_url_0').value;
        // values.trusted_form_url = trusted_form_url_value;
        if (currentPage === LastPage) {
            // alert(JSON.stringify(values, null, 2))
            this.postToOnePingTree(values)
        } else {
            bag.setTouched({})
            bag.setSubmitting(false)
        }
    }

    setPage = (page, props) => {
        return <div>{pages(props)[page]}</div>
    }

    render() {
        return (
            <Formik
                initialValues={this.state}
                validate={validate}
                onSubmit={this.handleSubmit}
                enableReinitialize={true}
                render={(props) => (
                    <Form>
                        <Heading>
                            <div
                                dangerouslySetInnerHTML={{ __html: this.props.pageData.title }}
                            />
                            {/* <Percent text={this.props.pageData.text} percentage={this.props.pageData.percent} /> */}
                        </Heading>
                        {this.setPage(this.props.page.pageIndex, (props = { ...props }))}
                    </Form>
                )}
            />
        )
    }
}

const mapStateToProps = ({ formState, page, meta, pageData }) => {
    return {
        formState,
        page,
        meta,
        pageData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAffParams: (params) => dispatch(setAffParams(params)),
        setIpAddress: (ip_address) => dispatch(setIpAddress(ip_address)),
        setUniversalLeadId: (universal_leadid) =>
            dispatch(setUniversalLeadId(universal_leadid)),
        setUserAgent: (user_agent) => dispatch(setUserAgent(user_agent)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormIndex)
