export const validate = (values) => {
  let errors = {};

  if (!values.currently_insured) {
    errors.currently_insured = 'Required';
  }

  // if (!values.gender) {
  //   errors.gender = 'Required';
  // }

  return errors;
};