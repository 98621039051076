import React from 'react'

const callUs = () => {
  return (
    <div className="flex-row">
      <div className="flex-small form-phone-number">
        <h4>Or Call Us Now</h4>
        <a href="tel:18443259235">(844) 325-9235</a>
      </div>
    </div>
  )
}

export default callUs
