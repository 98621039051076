import React from 'react'
import Buttons from './customButton'
import FormHeading from './formHeading'
import CallUs from './callUs'
// import FormPercent from './formPercent'
import { Link } from 'gatsby'
import Disclaimer from './disclaimer'
// const percentage = 30;

const submit = (props) => (
  <div className="button-container">
    <Buttons {...props} type="submit" text="GET MY QUOTE" className="primary-button full-button submit-button" />
    <Disclaimer>
    By completing a contact form on this site, clicking "GET MY QUOTE", or calling the number listed on this site, you may be directed to a sales agent who can answer your questions and provide information about health insurance plans and other services. (Agents are not connected with or endorsed by the U.S. government.) By interacting with the site, you provide an electronic signature by which you agree to the following terms: "I consent to receive emails, notifications, and calls about health insurance plans or products from <a href="https://blipmarketing.co/partners.php" target="_blank" rel="noopener noreferrer">these companies</a> and their agents to the telephone number(s) I have provided. 'Calls' may be auto-dialed, use artificial or pre-recorded voices, and/or be text messages, including recurring messages sent via a short code program. I understand that my consent to receive calls is not required in order to purchase any property, goods or services. My telephone company may impose additional charges for messages. I may revoke my consent to receiving messages at any time. By submitting my information, I confirm that I have read, understand, and agree to these <Link to="/terms-of-use">Terms of Use</Link> and <Link to="/privacy">Privacy Policy</Link>.
    </Disclaimer>
  </div>
)

const nextAndBack = (props) => (
  <div className="button-container">
    <Buttons {...props} type="back" text="Back" className="secondary-button half-button" />
    <Buttons {...props} type="next" text="Continue" className="primary-button half-button" />
  </div>
)

const typeOfButton = (props) => {
  switch (props.btnType) {
    case 'submit':
      return submit({...props})
    case 'two':
      return nextAndBack({...props})
    default:
      return null
  }
}

const FormWrapper = props => (
  <div className="form-content">
    {/* <FormPercent percentage={props.percentage} /> */}
    {/* <FormHeading text={props.heading} /> */}
    <div className="small-container">
      <div className="flex-row">
        <div className="flex-small">
          { props.children }
          {/* { props.submit ? submit({...props}) : nextAndBack({...props}) } */}
          {typeOfButton({...props})}
          <CallUs />
        </div>
      </div>
    </div>
  </div>
)

export default FormWrapper