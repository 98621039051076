import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setField, setPercent, setTitle, setText, setPageNumber } from '../../../state/action'
import FirstName from '@torchlight-technology/name-first-field'
import LastName from '@torchlight-technology/name-last-field'
import Email from '@torchlight-technology/email-field'
import FormWrapper from '../components/formWrapper'

class PageFour extends Component {
  
  componentDidMount(){
    this.props.setPageNumber(4)
    this.props.setPercent('70')
    this.props.setTitle(`<p>Perfect! <span class="highlight">You're almost done.</span> What's your name and E-Mail address?</p>`)
    this.props.setText("")
    
  }
  
  render(){
    return (
      <>
        <FormWrapper {...this.props}  heading={"First and Last Name and E-Mail address "} btnType="two">
          <FirstName {...this.props}  noErrorMessage />
          <LastName {...this.props}  noErrorMessage />
          <Email {...this.props}  noErrorMessage />
        </FormWrapper>
      </>
    )
  }
} 

const mapDispatchToProps = dispatch => {
  return {
    setField: field => dispatch( setField( field ) ),
    setTitle: title => dispatch( setTitle( title ) ),
    setPercent: percent => dispatch( setPercent( percent ) ),
    setText: text => dispatch( setText( text ) ),
    setPageNumber: number => dispatch( setPageNumber(number) ),
  }
}

export default connect( null, mapDispatchToProps )( PageFour )