import React from "react"
import { Field, ErrorMessage } from "formik"
import { connect } from 'react-redux'
import { nextPage } from '../../../state/action'

const validate = values => {
  if (!values) {
    return "Required"
  }
}

const SelectCurrentlyInsured = ({hideLabel, labelText, name, className, errorClass, placeholder, noErrorMessage, errors, touched, handleChange, setField, errorMessage}) => {
  return (
    <div className="ttg-field">
      {
        hideLabel ? 
        null : 
        <label htmlFor="currently_insured" name="currently_insured">
          { labelText || "Are You Currently Insured?" }
        </label>  
      }
      <Field
        className={ `${className || 'field'} ${
          errors[name || 'currently_insured'] && touched[name || 'currently_insured'] ? errorClass || "has-error" : ""
        }`}
        placeholder={ placeholder || "Are You Currently Insured?"}
        component="select"
        name={name || "currently_insured"}
        validate={validate}
        onChange={e => {
          handleChange(e)
          setField(e)
        }}
      >
        <option data-testid="currently_insured-none" value="">{placeholder || 'Select'}</option>
        <option data-testid="currently_insured-val-yes" value="1">Yes</option>
        <option data-testid="currently_insured-val-no" value="0">No</option>
      </Field>
      {
        noErrorMessage ?
        null :
        <ErrorMessage name={name || "currently_insured"} component="div" className="field-error" data-testid="currently_insured-error" />
      }
    </div>
  )
}

const RadioCurrentlyInsured = (props) => {
  return (
    <div className="ttg-field">
      <input 
        className="custom-radio-btn" 
        type="radio" 
        name="currently_insured" 
        id="yes" 
        value="1" 
        checked={'1' === props.values.currently_insured}
        onChange={ e => {
          props.handleChange(e)
          props.setField(e)
          window.scrollTo(0, 0)
        }}
        onBlur={props.onBlur}
        onClick={props.nextPage}
        />
      <label className="custom-radio-label" htmlFor="yes">Yes</label>
        
      <input 
        className="custom-radio-btn" 
        type="radio" 
        name="currently_insured" 
        id="no" 
        value="0"
        checked={'0' === props.values.currently_insured}
        onChange={ e => {
          props.handleChange(e)
          props.setField(e)
          window.scrollTo(0, 0)
        }}
        onBlur={props.onBlur}
        onClick={props.nextPage}
        />
      <label className="custom-radio-label" htmlFor="no">No</label>
    </div>
  )
}

const CurrentlyInsured = (props) => (
  props.radio ? RadioCurrentlyInsured(props) : SelectCurrentlyInsured(props)
)

const mapDispatchToProps = dispatch => {
  return {
    nextPage: () => dispatch( nextPage() )
  }
}

export default connect( null, mapDispatchToProps )( CurrentlyInsured )
