import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setField, setHomeCityState, clearHomeCityState, setPercent, setTitle, setText, setPageNumber } from '../../../state/action'
import Insured from '../components/currentlyInsured'
import FormWrapper from '../components/formWrapper'

class PageOne extends Component {
  
  componentDidMount(){
    this.props.setPageNumber(1)
    this.props.setPercent('0')
    this.props.setText('start')
    this.props.setTitle(`
    <p class="first-page-paragraph">Hi, I'm Janet and I'll be helping you <span class="highlight">get a quote</span> for a Medicare plan. It only takes a couple of minutes, and it's <span class="highlight">totally free!</span> First, a couple of questions about you.</p>
    `)
  }
  
  render(){
    return (
      <>
        <FormWrapper {...this.props}  heading={"Are you currently enrolled in Medicare parts A & B?"}>
          <h3 className="first-page-label text-center">Are you currently enrolled in Medicare parts A & B?</h3>
          <Insured {...this.props} radio setCityState hideLabel noErrorMessage />
        </FormWrapper>
      </>
    )
  }
} 

const mapDispatchToProps = dispatch => {
  return {
    setField: field => dispatch( setField( field ) ),
    setHomeCityState: data => dispatch( setHomeCityState(data)),
    clearHomeCityState: () => dispatch( clearHomeCityState() ),
    setTitle: title => dispatch( setTitle( title ) ),
    setPercent: percent => dispatch( setPercent( percent ) ),
    setText: text => dispatch( setText(text) ),
    setPageNumber: number => dispatch( setPageNumber(number) )
  }
}

export default connect( null, mapDispatchToProps )( PageOne )