import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setField, setPercent, setTitle, setText, setPageNumber } from '../../../state/action'
import Phone from '@torchlight-technology/phone-home-field'
import FormWrapper from '../components/formWrapper'

class PageFive extends Component {
  
  componentDidMount(){
    this.props.setPageNumber(5)
    this.props.setPercent('90')
    this.props.setTitle(`<p>Thanks ${this.props.formState.name_first}! Great to meet you. Just one more thing, and then we'll <span class="highlight">get your quotes.</span></p>`)
    this.props.setText("")
  }
  
  render(){
    return (
      <>
        <FormWrapper {...this.props}  heading={"Phone Number"} btnType="submit">
          <Phone {...this.props} hideLabel noErrorMessage />
        </FormWrapper>
      </>
    )
  }
} 
const mapStateToProps = ({ formState }) => {
  return { 
    formState
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setField: field => dispatch( setField( field ) ),
    setTitle: title => dispatch( setTitle( title ) ),
    setPercent: percent => dispatch( setPercent( percent ) ),
    setText: text => dispatch( setText( text ) ),
    setPageNumber: number => dispatch( setPageNumber(number) )
  }
}

export default connect( mapStateToProps, mapDispatchToProps )( PageFive )