import React from "react"
import Layout from "../components/layout"
import Form from "../components/form"
import SEO from "../components/seo"

const IndexPage = ({location}) => (

  <Layout location={location}>
    <SEO title="Get My Health Care" />
    <Form location={location} />
  </Layout>

)

export default IndexPage
