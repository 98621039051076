import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setField, setPercent, setTitle, setText, setPageNumber } from '../../../state/action'
import Gender from '../components/genderRadioBtn'
import DateOfBirth from '@torchlight-technology/date-of-birth-field'
import FormWrapper from '../components/formWrapper'

class PageThree extends Component {
  
  componentDidMount(){
    this.props.setPageNumber(3)
    this.props.setPercent('50')
    this.props.setTitle(`<p>Thanks! We have plan <span class="highlight">options available</span> in ${this.props.formState.us_state || 'your state'}! Now a few questions about you.</p>`)
    this.props.setText("")
    
  }
  
  render(){
    return (
      <>
        <FormWrapper {...this.props}  heading={"Date of Birth and Gender"} btnType="two">
          <DateOfBirth {...this.props}  noErrorMessage />
          <Gender {...this.props} noErrorMessage />
        </FormWrapper>
      </>
    )
  }
} 
const mapStateToProps = ({ formState }) => {
  return { 
    formState
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setField: field => dispatch( setField( field ) ),
    setTitle: title => dispatch( setTitle( title ) ),
    setPercent: percent => dispatch( setPercent( percent ) ),
    setText: text => dispatch( setText( text ) ),
    setPageNumber: number => dispatch( setPageNumber(number) ),
  }
}

export default connect( mapStateToProps, mapDispatchToProps )( PageThree )